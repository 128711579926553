import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { color as themeColors } from '@app/themes/mubi-theme';

import GoTicketSvg from '@app/components/go/GoTicketSvg';
import { MubiGoNewLogoIcon } from '@app/components/icons/LogoIcons';

type GoTicketProps = {
  director: string;
  filmTitle: string;
  bgColor?: string;
  textColor?: string;
  variant?: 'regular' | 'small';
};

const GoTicket = ({
  bgColor = themeColors.go.red,
  textColor = themeColors.go.pink,
  variant = 'regular',
  director,
  filmTitle,
}: GoTicketProps) => {
  const { t } = useTranslation();

  return (
    <TicketContainer bgColor={bgColor} variant={variant}>
      <BorderLeft bgColor={bgColor}>
        <GoTicketSvg />
      </BorderLeft>
      <BorderRight bgColor={bgColor} />
      <FirstColumn>
        <MubiGoNewLogoIcon
          color={textColor}
          width={variant === 'small' ? '72px' : '100px'}
        />
        <StyledText textColor={textColor} variant={variant}>
          {t('go:go.in_cinemas_this_week')}
        </StyledText>
      </FirstColumn>
      <SecondColumn>
        <StyledText textColor={textColor} variant={variant}>
          {filmTitle}
        </StyledText>
        <StyledText textColor={themeColors.white} variant={variant}>
          {director}
        </StyledText>
      </SecondColumn>
    </TicketContainer>
  );
};

export default GoTicket;

const TicketContainer = styled.div<{ bgColor: string; variant: string }>`
  display: flex;
  width: fit-content;
  min-height: ${props => (props.variant === 'small' ? '135px' : '165px')};
  margin-left: 10px;
  background-color: ${props => props.bgColor};
  position: relative;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 10px;
`;

const StyledText = styled.span<{ textColor: string; variant: string }>`
  color: ${props => props.textColor};
  width: min-content;
  text-transform: uppercase;
  font-size: ${props => (props.variant === 'small' ? '18px' : '24px')};
  line-height: 0.9;
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-block: 10px;
  max-width: 205px;
  margin-right: 10px;
  margin-left: 20px;
  min-width: 80px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-left: 35px;
  }
`;

const BorderLeft = styled.div<{ bgColor: string }>`
  background-color: ${props => props.bgColor};
  width: 10px;
  height: 100%;
  transform: translateX(-9px);
  clip-path: url('#clip-path');
  position: absolute;
  left: 0;
`;

const BorderRight = styled.div<{ bgColor: string }>`
  background-color: ${props => props.bgColor};
  width: 10px;
  height: 100%;
  transform: rotate(180deg) translateX(-9px);
  clip-path: url('#clip-path');
  position: absolute;
  right: 0;
`;
