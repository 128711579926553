import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { getImageUrl } from '@app/services/images';

import mubiTheme from '@app/themes/mubi-theme';

import { NotebookMasthead } from '@app/components/icons/NotebookIcons';
import ImageBase from '@app/components/ImageBase';
import PageSection from '@app/components/layout/PageSection';
import Link from '@app/components/Link';
import {
  MoreInfoCopyStyles,
  SplashCopy,
} from '@app/components/splash/SplashStyledComponents';

const SplashNotebookSection = () => {
  const { t } = useTranslation('splash');
  return (
    <Container>
      <PageSection>
        <NotebookSplashContent>
          <NotebookImage>
            <source
              srcSet={getImageUrl(
                '/static/images/notebook-magazine/magazine-stack.png',
                800,
              )}
              media={`(min-width: ${mubiTheme.mqNew.desktop})`}
            />
            <Img
              alt={t('splash:splash.notebook.img_alt')}
              src={getImageUrl(
                '/static/images/notebook-magazine/magazine-stack.png',
                500,
              )}
            />
          </NotebookImage>
          <NotebookTextContent>
            <MastheadContainer>
              <NotebookMasthead width="100%" color={mubiTheme.color.darkText} />
            </MastheadContainer>
            <SplashCopy>{t('splash:splash.notebook.copy')}</SplashCopy>
            <NotebookMoreInfo>
              {t('splash:splash.notebook.description')}
            </NotebookMoreInfo>
            <NotebookImageMobile
              alt={t('splash:splash.notebook.img_alt')}
              src={getImageUrl(
                '/static/images/notebook-magazine/magazine-stack.png',
                300,
              )}
            />
            <StyledLink href="/notebook/magazine">
              {t('splash:splash.notebook.cta')}
            </StyledLink>
          </NotebookTextContent>
        </NotebookSplashContent>
      </PageSection>
    </Container>
  );
};

const Container = styled.section`
  background-color: ${props => props.theme.color.midBackground};
  position: relative;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    height: 410px;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    height: 480px;
  }
`;

const NotebookSplashContent = styled.div`
  padding: 28px 0;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 70px 0;
    width: fit-content;
    margin: 0 auto;
  }
`;

const MastheadContainer = styled.div`
  width: 120px;
  margin-bottom: 28px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 180px;
  }
`;

const NotebookMoreInfo = styled.p`
  ${MoreInfoCopyStyles};
  margin: 20px 0 0;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin: 20px 0;
    display: flex;
  }
  color: ${props => props.theme.color.lightText};
`;

const NotebookImageMobile = styled(ImageBase)`
  width: 100%;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: none;
  }
`;

const NotebookImage = styled.picture`
  width: fit-content;
  width: 55%;
  max-height: 350px;
  display: none;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 0;
  }
  @media (min-width: ${props => props.theme.mqNew.wideLarge}) {
    width: 55%;
  }
`;

const NotebookTextContent = styled.div`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 45%;
    margin-left: auto;
    position: relative;
  }
`;

const Img = styled(ImageBase)`
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 450px;
`;

const StyledLink = styled(Link)`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.darkText};

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 18px;
  }

  &:hover {
    opacity: 0.8;
  }
  &:visited {
    color: ${props => props.theme.color.darkText};
  }
  &:hover {
    color: ${props => props.theme.color.darkText};
  }
`;

export default SplashNotebookSection;
