import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { MubiGoProgramming } from '@app/api/resources/MubiGo';

import { getImageUrl } from '@app/services/images';

import mubiTheme, { color as themeColor } from '@app/themes/mubi-theme';

import { useGetGoAvailabilityForCountry } from '@app/hooks/helpers/useGetGoAvailabilityForCountry';
import useIsCountry from '@app/hooks/helpers/useIsCountry';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import GoLink from '@app/components/go/GoLink';
import GoTicket from '@app/components/go/GoTicket';
import { MubiGoNewLogoIcon } from '@app/components/icons/LogoIcons';
import ImageBase from '@app/components/ImageBase';
import PageSection from '@app/components/layout/PageSection';

type SplashMubiGoNewProps = {
  mubiGoProgrammingOfTheWeek: MubiGoProgramming;
};

const SplashMubiGoNew = ({
  mubiGoProgrammingOfTheWeek,
}: SplashMubiGoNewProps) => {
  const { t } = useTranslation();

  const isMobile = useAppSelector(state => state.appState.isMobile);

  const isUS = useIsCountry('US');

  const { isPremiumPlanInCountry } = useGetGoAvailabilityForCountry();

  if (!isPremiumPlanInCountry) {
    return null;
  }

  const { film: filmOfTheWeek } = mubiGoProgrammingOfTheWeek;

  const imgUrl =
    mubiGoProgrammingOfTheWeek?.short_mobile_image?.url ||
    filmOfTheWeek.still_url;

  return (
    <Container>
      <Content>
        <PageSection>
          <FirstColumn>
            <IconWrap>
              <MubiGoNewLogoIcon
                width="100%"
                color={themeColor.go.goldenYellow}
              />
            </IconWrap>
            <div>
              <TextContainer>
                <TextGold>
                  {isUS
                    ? t('go:go.cinema_ticket_every_week_us')
                    : t('go:go.cinema_ticket_every_week')}
                </TextGold>
                <TextWhite>+</TextWhite>
                <TextWhite>
                  {isUS
                    ? t('go:go.all_the_movies_us')
                    : t('go:go.all_the_movies')}
                </TextWhite>
              </TextContainer>
              <StyledLink>
                <GoLink>{t('splash:splash.notebook.cta')}</GoLink>
              </StyledLink>
            </div>
          </FirstColumn>
        </PageSection>
        <SecondColumn>
          <picture>
            <source
              srcSet={getImageUrl(imgUrl, 1000)}
              media={`(min-width: ${mubiTheme.mqNew.desktop})`}
            />
            <source
              srcSet={getImageUrl(imgUrl, 800)}
              media={`(min-width: ${mubiTheme.mqNew.tablet})`}
            />
            <Img src={getImageUrl(imgUrl, 660)} alt={filmOfTheWeek.title} />
          </picture>
          <TicketWrap>
            <GoTicket
              director={filmOfTheWeek.directors[0].name}
              filmTitle={filmOfTheWeek.title}
              variant={isMobile ? 'small' : 'regular'}
            />
          </TicketWrap>
        </SecondColumn>
      </Content>
    </Container>
  );
};

export default SplashMubiGoNew;

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.go.brown};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledLink = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  a {
    transition: opacity 0.4s ease-out;
    color: ${props => props.theme.color.white};

    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }
  }

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 18px;
  }
`;

const IconWrap = styled.div`
  width: 124px;
  padding-bottom: 40px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 220px;
  }
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    height: 600px;
    padding-top: 45px;
    padding-bottom: 45px;
  }
`;

const SecondColumn = styled.div`
  width: 100%;
  height: 275px;
  position: relative;
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-bottom: 0;
    height: 600px;
    position: absolute;
    width: 55%;
    right: 0;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: 60%;
  }
  @media (min-width: ${props => props.theme.mqNew.wideLarge}) {
    width: 55%;
  }
`;

const TicketWrap = styled.div`
  position: absolute;
  bottom: -30px;
  right: 0;
  z-index: 2;
  transform: rotate(-20deg);

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    right: 8%;
    bottom: 80px;
  }
`;

const Img = styled(ImageBase)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextContainer = styled.div`
  font-size: 24px;
  max-width: 230px;
  text-transform: uppercase;
  line-height: 1;
  padding-bottom: 30px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    max-width: 330px;
    font-size: 34px;
  }
`;

const TextWhite = styled.p`
  color: ${props => props.theme.color.white};
`;

const TextGold = styled.p`
  color: ${props => props.theme.color.go.goldenYellow};
`;
