import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { SplashAssets } from '@app/api/resources/AppConfig';

import HeaderContainer from '@app/components/layout/HeaderContainer';
import PageSection from '@app/components/layout/PageSection';
import BannerContainer from '@app/components/promotional-banner/BannerContainer';
import SplashVideo from '@app/components/splash/splash-head/SplashVideo';
import TextScrollAnimation from '@app/components/splash/splash-head/TextScrollAnimation';
import SplashFormCTA from '@app/components/splash/SplashFormCTA';
import VerticalDotNav from '@app/components/splash/VerticalDotNav';
import TranslateHtml from '@app/components/TranslateHtml';

type SplashHeadProps = {
  splashVideos: SplashAssets['video'];
};

const NO_OF_INTRO_LINES = 3;

const SplashHead = ({ splashVideos }: SplashHeadProps) => {
  const { t } = useTranslation('splash');
  const [visibleLine, setVisibleLine] = useState(0);

  const setLastVisibleLine = () => setVisibleLine(NO_OF_INTRO_LINES - 1);

  const { ref, inView } = useInView({
    rootMargin: '100px 0px 100px 0px',
  });

  return (
    <>
      <div ref={ref}>
        <ScrollableSpace />
      </div>
      <Container>
        <BannerContainer />
        <PageContent>
          <HeaderContainer variant="simplified" />
          <VideoPosition>
            {inView && <SplashVideo splashVideos={splashVideos} />}
          </VideoPosition>
          <PageSection fullHeight>
            <TextContent>
              <CopyPosition>
                <TextScrollAnimation
                  onLineChange={setVisibleLine}
                  onScrollPastLastLine={setLastVisibleLine}
                  instanceId="splash-above-the-fold"
                  lineScrollZoneLength={800}
                  translateYCoefficient={0.06}
                >
                  <CopyLine>
                    <TranslateHtml
                      t={t}
                      i18nKey="splash:splash.intro.line1"
                      breakNode={<MobileAndTabletBreak />}
                      key="splash:splash.intro.line1"
                    />
                  </CopyLine>
                  <CopyLine>
                    <TranslateHtml
                      t={t}
                      i18nKey="splash:splash.intro.line2"
                      key="splash:splash.intro.line2"
                    />
                  </CopyLine>
                  <CopyLine>
                    <TranslateHtml
                      t={t}
                      i18nKey="splash:splash.intro.line3"
                      key="splash:splash.intro.line3"
                    />
                  </CopyLine>
                </TextScrollAnimation>
              </CopyPosition>
              <CtaPosition>
                <SplashFormCTA snowplowElement="cta_top" hidePrice />
              </CtaPosition>
            </TextContent>
          </PageSection>
          <DotsPosition>
            <VerticalDotNav
              numberOfDots={NO_OF_INTRO_LINES}
              currentDot={visibleLine}
              instanceId="splash-above-the-fold"
            />
          </DotsPosition>
        </PageContent>
      </Container>
    </>
  );
};

export default SplashHead;

const ScrollableSpace = styled.div`
  height: 2600px;
  position: relative;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${props => props.theme.color.white};
`;

const PageContent = styled.div`
  position: relative;
  height: 100%;
`;

const TextContent = styled.div`
  position: relative;
  height: 72%;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    height: 77%;
  }
`;

const VideoPosition = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const CopyPosition = styled.div`
  position: absolute;
  top: 17%;
  width: 100%;
`;

const CopyLine = styled.div`
  font-size: 30px;
  line-height: 32px;
  font-weight: 500;
  text-transform: uppercase;
  /* most "lines" are actually 2 lines high, so for consistency, make them all at least that height */
  min-height: 64px;
  /* centered in the space all the way to top of page, so here we account for the nav */
  margin-top: 0;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 50px;
    line-height: 1;
    min-height: 64px;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 60px;
    min-height: 120px;
  }
`;

const CtaPosition = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 625px;
`;

const DotsPosition = styled.div`
  position: absolute;
  right: 14px;
  top: 22%;
  bottom: 0;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    right: 44px;
    top: 26%;
  }
`;

const MobileAndTabletBreak = styled.br`
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: none;
  }
`;
